import heart from './heart33.jpg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={heart} className="App-logo" alt="huge" />
          <p><b>
	  February 14, 2022
      <br></br>
          all the magikz snowfall<br></br>
	  poignant<br></br>
	  strayings<br></br>
	  uncountable<br></br>
	  intooutof<br></br>
	  heartbeat warmth<br></br>
	  feltseenheldheard<br></br>
	  tears<br></br>
	  i give you<br></br>
	  all the imperfections<br></br>
	  i give you<br></br>
	  all the
          </b>
        </p>          <p><b>
	  October 21, 2021
      <br></br>
          Uncloaked in<br></br>
unconventional<br></br>
beauty sizzle melt<br></br>
smartheart<br></br>
full catastrophe bubble wrap<br></br>
misspelled future tense<br></br>
you are the icing and the cake and the breath<br></br>
of this space.
          </b>
        </p>
      </header>
    </div>
  );
}

export default App;
